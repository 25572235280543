
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { userStore } from '@/store/userStore'
import { showError } from '@/helpers/notifications'

@Component({
  components: { BButton, BForm, BFormGroup, BFormInput, BCard, BOverlay },
})
export default class Login extends Vue {
  email = ''
  password = ''

  pending = false

  async submit() {
    this.pending = true
    if (await userStore.login({ email: this.email, password: this.password })) {
      this.$router.push('/')
    } else {
      showError('Неверный e-mail или пароль')
    }
    this.pending = false
  }
}
